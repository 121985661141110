/* eslint-disable */

let circle

let MAX = 10
let RADIUS = 175
let wobblyPitch = 50
let wobblyPitchGap = 50
let wobblyVol = 50
let wobblyVolGap = 50
let circleColor = 'white'
let strokeColor = 'red'
let bgColor = 'red'
let strokeWidth = 0
let maskFlag = false

let FPS = 60
let canvas, ctx, timer, point
let CENTER = { x: 400, y: 350 }

let Point = function (c, r, rota) {
  this.x, this.y
  this.centerX = c.x
  this.centerY = c.y
  this.radian = rota * (Math.PI / 180) //
  this.radius = r

  this.speed =
    Math.random() * 10 * (wobblyPitchGap / 50) + 5 * (wobblyPitch / 50)
  this.r = Math.random() * 0.1 * (wobblyVolGap / 50) + 1 * (wobblyVol / 50)
  this.rota = 0

  this.update = function () {
    var plus = Math.cos(this.rota * (Math.PI / 180)) * this.r

    this.radius += plus
    var cos = Math.cos(this.radian) * this.radius
    var sin = Math.sin(this.radian) * this.radius

    this.x = cos + this.centerX
    this.y = sin + this.centerY

    this.rota += this.speed

    if (this.rota > 360) {
      this.rota -= 360
    }
  }
}

function initialize() {
  var rota = 360 / MAX
  var i
  for (i = 0; i < MAX; i++) {
    point[i] = new Point(CENTER, RADIUS, rota * i)
  }
}

function update() {
  for (var i = 0; i < MAX; i++) {
    point[i].update()
  }
  draw()
}

function draw() {
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  ctx.fillStyle = 'rgba(0, 0, 0, 0)'

  ctx.beginPath()

  var xc1 = (point[0].x + point[MAX - 1].x) / 2
  var yc1 = (point[0].y + point[MAX - 1].y) / 2

  ctx.moveTo(xc1, yc1)

  for (var i = 0; i < MAX - 1; i++) {
    var xc = (point[i].x + point[i + 1].x) / 2
    var yc = (point[i].y + point[i + 1].y) / 2

    ctx.quadraticCurveTo(point[i].x, point[i].y, xc, yc)
  }

  ctx.quadraticCurveTo(point[i].x, point[i].y, xc1, yc1)

  ctx.strokeStyle = strokeColor

  ctx.fillStyle = circleColor
  ctx.lineWidth = strokeWidth
  ctx.closePath()
  ctx.fill()
  if (strokeWidth > 0) {
    ctx.stroke()
  }
}

function floatFormat(n) {
  return Math.round(n * 100) / 100
}

function invokeTheCircle() {
  canvas = document.getElementById('canvas')
  ctx = canvas.getContext('2d')
  if (point === undefined || point.length == 0) {
    point = []
    initialize()
  }
  startTheCircle()
}

function startTheCircle() {
  timer = setInterval(update, 1000 / FPS)
}

function killTheCircle() {
  clearInterval(timer)
}

export { invokeTheCircle, killTheCircle }
