//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { routeTransitionFade } from '@/mixins/route-transitions'

export default {
  mixins: [routeTransitionFade],
  async asyncData({ $prismic, params, error }) {
    const document = await $prismic.api.getSingle('home_page')
    if (document) {
      return document.data
    } else {
      error({ statusCode: 404, message: 'Page not found' })
    }
  },
  mounted() {
    this.$app.$emit('page::mounted')
  },
}
