//
//
//
//
//
//
//
//
//
//
//
//
//

import { invokeTheCircle, killTheCircle } from '../static/js/wobblycircle.js'

export default {
  data() {
    return {
      circleVisible: false,
    }
  },
  computed: {
    circleIsVisible() {
      return this.circleVisible
    },
  },
  beforeDestroy() {
    this.hideCircle()
  },
  mounted() {
    this.showCircle()
  },
  methods: {
    showCircle() {
      invokeTheCircle()
      this.circleVisible = true
    },
    hideCircle() {
      killTheCircle()
      this.circleVisible = false
    },
  },
}
